import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ElementReferenceService {

    private itemTemplateDict: { [name: string]: TemplateRef<any> } = {};

    setItemTemplate(name: string, itemTemplate: TemplateRef<any>) {
        this.itemTemplateDict[name] = itemTemplate;
    }

    getItemTemplate(name: string) {
        return this.itemTemplateDict[name];
    }
}
